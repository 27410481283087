<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-data-table
        class="text-center"
        :apiMode="true"
        :apiUrl="apiUrl"
        :httpOptions="httpOptions"
        :defaultPerPage="defaultTablePerPage"
        :tableFields="translatedTableFields"
        :perPageSelectorShown="false"
        :actions="true"
        :actionsType="'invoices'"
        :filterInputShown="false"
      ></vuestic-data-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import StatusColumn from '../packages/helpers/status-column'
import PaymentColumn from '../packages/helpers/payment-column'
import momentTimezone from 'moment-timezone'
Vue.component('status-column', StatusColumn)
Vue.component('payment-column', PaymentColumn)
export default {
  data() {
    return {
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'ngrok-skip-browser-warning': true,
          organization: localStorage.getItem('organizationId'),
          'Accept-Language': localStorage.getItem('locale'),
          timezone: momentTimezone.tz.guess()
        }
      },
      tableFields: [
        {
          name: '__sequence',
          dataClass: 'text-light',
          visible: false
        },
        {
          name: 'number',
          title: 'billing.invoices.table.number'
        },
        {
          name: 'date',
          title: 'billing.invoices.table.date'
        },
        {
          name: 'email',
          title: 'billing.invoices.table.email'
        },
        {
          name: 'total',
          title: 'billing.invoices.table.amount'
        },
        {
          name: 'currencyCode',
          title: 'billing.invoices.table.currency'
        },
        {
          name: '__component:status-column',
          title: 'billing.invoices.table.status'
        },
        {
          name: '__component:payment-column',
          title: 'billing.invoices.table.actions'
        }
      ],
      defaultTablePerPage: 10
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    apiUrl() {
      return `${process.env.VUE_APP_NEW_API}zoho/customer-invoices?itemsPerPage=${this.defaultTablePerPage}`
    },
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          title: this.$t(field.title)
        }
      })
    }
  },
  watch: {
    currentTenant() {
      const organizationId = localStorage.getItem('organizationId')
      this.httpOptions.headers.organization = organizationId
    }
  }
}
</script>
