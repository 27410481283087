<template>
  <div class="payment-col">
    <half-circle-spinner
      class="mx-auto"
      :animation-duration="1500"
      :size="20"
      color="#008ecf"
      v-if="loading"
    />
    <div v-else class="cursor-pointer" @click="onUrlClick">
      <i class="fa fa-money text-primary"></i>
    </div>
  </div>
</template>

<script>
import { api } from '../../../axios'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  name: 'PaymentColumn',
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      loading: false
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  methods: {
    async onUrlClick() {
      this.loading = true
      try {
        const res = await api
          .get(`zoho/invoice-url/${this.rowData.id}`, {
            headers: {
              'ngrok-skip-browser-warning': '69420'
            }
          })
          .catch(console.log)
        window.open(res.data, '_blank')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
