<template>
  <span
    class="badge"
    :class="
      rowData.status == 'overdue'
        ? 'badge-danger'
        : rowData.status == 'paid'
        ? 'badge-success'
        : 'badge-warning'
    "
  >
    {{ rowData.status }}
  </span>
</template>

<script>
export default {
  name: 'StatusColumn',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>
